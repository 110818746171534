import { render } from "@itsjavi/jsx-runtime"

const Player = ({ player }) => (
	<span className="player">
		<img className="img-inline" src={`https://www.mc-heads.net/avatar/${player}/32.png`} />
		{player}
	</span>
)

type Screenshot = {
	file: string
	season: number
	location?: string
	by: string
	active?: boolean
}

const CarouselItem = ({ file, season, location, by, active }: Screenshot): JSX.Element => (
	<div className={["carousel-item", active ? "active" : undefined]}>
		<img {...{ [active ? "src" : "data-src"]: "./screenshots/" + file }} />
		<div className="carousel-caption">
			Season {season}<br />
			{location != undefined ? <>{location}<br /></> : <></>}
			<Player player={by} />
		</div>
	</div>
)

const Thumbnail = ({ file }: Screenshot): JSX.Element =>
	<img className="thumbnail" src={"./screenshots/thumbnails/" + file} data-thumbnail-for={"./screenshots/" + file} />

const ThumbnailGroup = (screenshots: Screenshot[]): JSX.Element =>
	<div className="thumbnail-group" data-season={`SEASON ${screenshots[0].season}`}>{screenshots.map(Thumbnail)}</div>

export const Carousel = ({ screenshots }: { screenshots: Screenshot[] }) => <>
	<div className="carousel-inner">{screenshots.map(CarouselItem)}</div>
	<div className="carousel-controls">
		<a data-control="left" title="Previous (left arrow)"><span className="icon icon-arrow-left"></span></a>
		<a data-control="hideui" title="Toggle UI (h)"><span className="icon icon-hideui"></span></a>
		<a data-control="open" title="Open original image" target="_blank"><span className="icon icon-open-image"></span></a>
		<a data-control="thumbnails" title="Toggle thumbnails (t)"><span className="icon icon-bottom-bar"></span></a>
		<a data-control="playpause" title="Play / pause (p)"><span className="icon icon-playpause"></span></a>
		<a data-control="right" title="Next (right arrow)"><span className="icon icon-arrow-right"></span></a>
	</div>
	<div className="thumbnails">{Object.values(Object.groupBy(screenshots, s => s.season)).map(ThumbnailGroup)}</div>
</>

const loadSlide = (slide: HTMLElement) => {
	const img = slide.querySelector("img[data-src]")
	if (img == undefined) return
	img.setAttribute("src", img.getAttribute("data-src"))
	img.removeAttribute("data-src")
}

export default class Slideshow {
	elem: HTMLElement
	interval: number
	intervalID?: number
	lastScrollInThumbnails?: number

	get playing() { return this.elem.classList.contains("playing") }
	get current() { return this.elem.querySelector(".carousel-item.active") as HTMLElement }
	get currentSrc() { return this.current.querySelector("img").getAttribute("src") }
	get first() { return this.elem.querySelector(".carousel-item") as HTMLElement }
	get last() { return this.elem.querySelector(".carousel-item:last-child") as HTMLElement }

	constructor(elem: HTMLElement, interval: number, screenshots: Screenshot[]) {
		this.elem = elem
		this.interval = interval

		render(<Carousel screenshots={screenshots} />, elem)

		elem.querySelector("[data-control=left]").addEventListener("click", () => this.prevSlide())
		elem.querySelector("[data-control=hideui]").addEventListener("click", () => this.hideUI())
		elem.querySelector("[data-control=thumbnails]").addEventListener("click", () => this.toggleThumbnails())
		elem.querySelector("[data-control=playpause]").addEventListener("click", () => this.playPause())
		elem.querySelector("[data-control=right]").addEventListener("click", () => this.nextSlide())
		document.addEventListener("keydown", e => {
			if (e.key == "ArrowLeft") this.prevSlide()
			else if (e.key == "ArrowRight") this.nextSlide()
			else if (e.key == "p") this.playPause()
			else if (e.key == "h") this.hideUI()
			else if (e.key == "t") this.toggleThumbnails()
			else return
			e.preventDefault()
			return false
		})
		document.addEventListener("visibilitychange", () => this.restartAutoPlay())
		elem.querySelectorAll(".thumbnail").forEach(thumbnailElem =>
			thumbnailElem.addEventListener("click", e =>
				this.selectSlideFromThumbnail(e.currentTarget as HTMLElement)))
		elem.querySelector(".thumbnails").addEventListener("scroll", e => {
			this.lastScrollInThumbnails = Date.now()
		})

		this.restartAutoPlay()
		this.updateOriginalImageSource()
		this.highlightCurrentThumbnail()
	}

	restartAutoPlay() {
		clearInterval(this.intervalID)
		if (this.playing && !document.hidden) {
			this.intervalID = setInterval(() => this.nextSlide(true), this.interval)
		}
	}

	updateOriginalImageSource() {
		const origSrc = this.currentSrc?.replace(/\.webp$/, ".png")
		this.elem.querySelector("[data-control=open]").setAttribute("href", origSrc)
	}

	highlightCurrentThumbnail(auto = false) {
		const thumbnail = this.elem.querySelector(`img[data-thumbnail-for="${this.currentSrc}"]`) as HTMLElement
		this.elem.querySelectorAll(".thumbnail.selected").forEach(thumbnailElem => thumbnailElem.classList.remove("selected"))
		thumbnail.classList.add("selected")
		if (!auto || this.lastScrollInThumbnails == undefined || this.lastScrollInThumbnails < Date.now() - 2000) {
			// Wait at least 2 seconds after user scrolled before auto-scrolling
			this.scrollThumbnailToCentre(thumbnail)
		}
	}

	async selectSlide(slide: HTMLElement, auto = false) {
		loadSlide(slide)
		const img = slide.querySelector("img")

		// Wait for the image to load
		if (!img.complete) await new Promise(resolve => img.addEventListener("load", resolve, { once: true }))

		this.restartAutoPlay()
		this.current.classList.remove("active")
		slide.classList.add("active")

		this.updateOriginalImageSource()
		this.highlightCurrentThumbnail(auto)
	}

	selectSlideFromThumbnail(thumbnail: HTMLElement) {
		const src = thumbnail.getAttribute("data-thumbnail-for")
		const slide = this.elem.querySelector(`img[src="${src}"]`) ?? this.elem.querySelector(`img[data-src="${src}"]`)
		this.selectSlide(slide.parentElement)
	}

	scrollThumbnailToCentre(thumbnail: HTMLElement) {
		const thumbnails = this.elem.querySelector(".thumbnails")
		thumbnails.scrollTo({
			left: thumbnail.offsetLeft - thumbnails.clientWidth / 2 + thumbnail.clientWidth / 2,
			behavior: "smooth"
		})
	}

	nextSlide(auto = false) {
		this.selectSlide(this.current.nextElementSibling as HTMLElement ?? this.first, auto)
	}

	prevSlide(auto = false) {
		this.selectSlide(this.current.previousElementSibling as HTMLElement ?? this.last, auto)
	}

	playPause() {
		this.elem.classList.toggle("playing")
		this.restartAutoPlay()
	}

	hideUI() {
		const hidden = document.documentElement.classList.toggle("hide-ui")
		document.documentElement.classList.toggle("hide-ui-manual", hidden)
	}

	toggleThumbnails() {
		this.elem.classList.toggle("thumbnails-visible")
	}
}
